<template>
  <div class="word-det">
    <div class="w">
      <div class="docDet">
        <div class="title">{{ form.docTitle }}</div>

        <div class="content" v-html="form.htmlContent"></div>
        <div class="ft">
          <div class="it">上传:{{ form.userNickName }}</div>
          <div class="it">阅读:{{ form.playNum }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Store from '../../store';
// import Box3 from '../home/box3.vue';
export default {
  components: {
    // Box3,
  },

  data() {
    return {
      uti: Store.uti,
      store: Store.state,
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: '',
      },
    };
  },

  mounted() {
    if (window.sessionStorage.getItem('actDocId')) {
      this.store.actDocId = window.sessionStorage.getItem('actDocId');
      this.getDocDet();
    }
  },
  methods: {
    // 获取文档详情
    async getDocDet() {
      // https://www.youtube.com/watch?v=riJYZZIybcc
      let { data: res } = await this.$http.post(
        `/learnDoc/details/${this.store.actDocId}`
      );
      if (res.code !== 200) {
        return this.$message.error(res.message);
      }
      this.form = res.data;
    },
  },
};
</script>

<style lang="less" scoped>
.word-det {
  // height: 600px;
  // margin-top: 200px;
  padding-bottom: 50px;
  .docDet {
    /* 矩形 9 */
    margin: 0 auto;
    width: 1095px;
    border-radius: 10px;
    background: #ffffff;
    box-sizing: border-box;
    border: 1px solid #eee;
    box-shadow: 0px 4px 10px 0px rgba(153, 153, 153, 0.3);
    padding: 40px;

    .title {
      /* PSC简介 */
      font-family: SourceHanSansCN-Medium;
      font-size: 24px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 20px;
      letter-spacing: 0em;
      color: #333333;
    }
    .content {
      margin: 20px 0;
    }
    .ft {
      display: flex;
      /* 上传：芜湖大司马 */
      font-family: SourceHanSansCN-Regular;
      font-size: 14px;
      font-weight: normal;
      letter-spacing: 0em;
      color: #999999;
      .it {
        margin-right: 50px;
      }
    }
  }
}
</style>
